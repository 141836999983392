import {
  validateEmail,
  validateRequired,
  Validator,
} from 'application/utils/validators';

// eslint-disable-next-line max-len
export const SET_IS_MODULE_LOADED = '[form] sd-work-with-us module/isModuleLoaded';
export type SetIsModuleLoadedAction = {
  type: typeof SET_IS_MODULE_LOADED,
};
export const setIsModuleLoaded = {
  type: SET_IS_MODULE_LOADED,
};

// eslint-disable-next-line max-len
export const SUBMIT_FORM_SD_BUSINESS_WORK_WITH_US = '[form] submit form sd business work';
export type SubmitForm = {
  type: typeof SUBMIT_FORM_SD_BUSINESS_WORK_WITH_US;
}
export const submitForm = {
  type: SUBMIT_FORM_SD_BUSINESS_WORK_WITH_US,
};

// eslint-disable-next-line max-len
export const SET_FORM_LOADING_SD_BUSINESS_WORK_WITH_US = '[forms] set loading sd business work';
export type SetFormLoading = {
  type: typeof SET_FORM_LOADING_SD_BUSINESS_WORK_WITH_US;
  payload: {
    isLoading: boolean;
  }
};

export const setFormLoading = (
  isLoading: boolean,
): SetFormLoading => ({
  type: SET_FORM_LOADING_SD_BUSINESS_WORK_WITH_US,
  payload: {
    isLoading,
  },
});

// eslint-disable-next-line max-len
export const SET_CHECKBOX_WORK_WITH_US = '[forms] checkbox change work with us';

export type SetCheckbox = {
  type: typeof SET_CHECKBOX_WORK_WITH_US;
  payload: {
    groupName: string;
    option: string;
    isChecked: boolean;
    validators: Validator[];
  }
}
export type SetCheckboxValidated = {
  type: typeof SET_CHECKBOX_WORK_WITH_US;
  payload: {
    [groupName: string]: {
      value: { [key: string]: boolean; };
      error: string;
    }
  }
}
export const setRequiredCheckbox = (
  groupName: string,
  option: string,
) => (
  isChecked: boolean,
): SetCheckbox => ({
  type: SET_CHECKBOX_WORK_WITH_US,
  payload: {
    groupName,
    option,
    isChecked,
    validators: [validateRequired],
  },
});
export const setCheckbox = (
  groupName: string,
  option: string,
) => (
  isChecked: boolean,
): SetCheckbox => ({
  type: SET_CHECKBOX_WORK_WITH_US,
  payload: {
    groupName,
    option,
    isChecked,
    validators: [],
  },
});

// eslint-disable-next-line max-len
export const SET_FORM_SUCCESS_SD_BUSINESS_WORK_WITH_US = '[forms] set success sd business work';
export type SetFormSuccess = {
  type: typeof SET_FORM_SUCCESS_SD_BUSINESS_WORK_WITH_US;
  payload: {
    isSuccess: boolean;
  }
};

export const setFormSuccess = (
  isSuccess: boolean,
): SetFormSuccess => ({
  type: SET_FORM_SUCCESS_SD_BUSINESS_WORK_WITH_US,
  payload: {
    isSuccess,
  },
});

export const RESET_FORM_SD_BUSINESS_WORK_WITH_US = '[sd-work-with] form reset';
export type ResetForm = {
  type: typeof RESET_FORM_SD_BUSINESS_WORK_WITH_US,
};

export const resetForm: ResetForm = {
  type: RESET_FORM_SD_BUSINESS_WORK_WITH_US,
};

// eslint-disable-next-line max-len
export const SET_INPUT_SD_BUSINESS_WORK_WITH_US = '[forms] input change sd business work';

export type SetInput = {
  type: typeof SET_INPUT_SD_BUSINESS_WORK_WITH_US;
  payload: {
    fieldName: string;
    value: string;
    validators: Validator[];
  }
}
export type SetInputValidated = {
  type: typeof SET_INPUT_SD_BUSINESS_WORK_WITH_US;
  payload: {
    [fieldName: string]: {
      value: string;
      error: string;
    }
  }
}

export const setRequiredInput = (fieldName: string) => (
  value: string,
): SetInput => ({
  type: SET_INPUT_SD_BUSINESS_WORK_WITH_US,
  payload: {
    fieldName,
    value,
    validators: [validateRequired],
  },
});

export const setRequiredEmail = (fieldName: string) => (
  value: string,
): SetInput => ({
  type: SET_INPUT_SD_BUSINESS_WORK_WITH_US,
  payload: {
    fieldName,
    value,
    validators: [validateRequired, validateEmail],
  },
});

// eslint-disable-next-line max-len
export const SET_PHONE_INPUT_SD_BUSINESS_WORK_WITH_US = '[forms] phone number change sd business work';
export type SetPhoneInput = {
  type: typeof SET_PHONE_INPUT_SD_BUSINESS_WORK_WITH_US;
  payload: {
    countryCode: string;
    number: string;
    isRequired: boolean;
  }
}
export type SetPhoneInputValidated = {
  type: typeof SET_PHONE_INPUT_SD_BUSINESS_WORK_WITH_US;
  payload: {
    phoneNumber: {
      countryCode: string;
      number: string;
      error: string;
    };
  }
}

export const setRequiredPhoneInput = (
  countryCode: string,
  number: string,
): SetPhoneInput => ({
  type: SET_PHONE_INPUT_SD_BUSINESS_WORK_WITH_US,
  payload: {
    countryCode,
    number,
    isRequired: true,
  },
});

// eslint-disable-next-line max-len
export const SET_GENERAL_ERROR_SD_BUSINESS_WORK = '[forms] set general error sd business form';
export type SetGeneralError = {
  type: typeof SET_GENERAL_ERROR_SD_BUSINESS_WORK;
  payload: string;
}

export const setGeneralError = (message: string): SetGeneralError => ({
  type: SET_GENERAL_ERROR_SD_BUSINESS_WORK,
  payload: message,
});

export type SdWorkWithUsActions =
  | SetIsModuleLoadedAction
  | SetInputValidated
  | SetPhoneInputValidated
  | SetFormLoading
  | SetFormSuccess
  | SetCheckboxValidated
  | SetGeneralError
  | ResetForm;
