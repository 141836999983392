import { Middleware } from 'redux';
import { AppError } from 'src/application/entities/app-error';
import { Services } from 'src/application/interfaces';
import { AppState } from 'src/application/state';
import { Store } from 'src/application/middleware';
import { validateEmail, validateRequired } from 'application/utils/validators';
import {
  LOAD_COMPONENT_SUCCESS,
  LoadComponentSuccess,
} from 'application/core/control/actions';
import {
  setIsModuleLoaded,
  setFormLoading,
  setFormSuccess,
  SetInput,
  SetInputValidated,
  SetPhoneInput,
  SetPhoneInputValidated,
  setRequiredEmail,
  setRequiredInput,
  setRequiredPhoneInput,
  SET_INPUT_SD_BUSINESS_WORK_WITH_US,
  SET_PHONE_INPUT_SD_BUSINESS_WORK_WITH_US,
  SubmitForm,
  SUBMIT_FORM_SD_BUSINESS_WORK_WITH_US,
  setGeneralError,
  SET_CHECKBOX_WORK_WITH_US,
  SetCheckbox,
  SetCheckboxValidated,
} from './actions';

export const loadComponentSuccessMiddleware = (): Middleware => ({
  dispatch,
}) => (next) => async (
  action: LoadComponentSuccess,
): Promise<void> => {
  if (
    action.type === LOAD_COMPONENT_SUCCESS
  ) {
    dispatch(setIsModuleLoaded);
  }
  next(action);
};

export const InputChanged = (
): Middleware => () => (next) => (action: SetInput): void => {
  if (action.type === SET_INPUT_SD_BUSINESS_WORK_WITH_US) {
    const { payload } = action as SetInput;

    let error = '';
    payload.validators.forEach((validate) => {
      const errorCode = validate(payload.value);
      if (errorCode) {
        error = errorCode;
      }
    });

    const validatedPayload: SetInputValidated['payload'] = {
      [payload.fieldName]: {
        value: payload.value,
        error,
      },
    };

    next({
      ...action,
      payload: validatedPayload,
    });
    return;
  }

  next(action);
};

export const PhoneInputChanged = (
): Middleware => () => (next) => (action): void => {
  if (action.type === SET_PHONE_INPUT_SD_BUSINESS_WORK_WITH_US) {
    const { payload } = action as SetPhoneInput;

    const validatedPayload: SetPhoneInputValidated['payload'] = {
      phoneNumber: {
        countryCode: payload.countryCode,
        number: payload.number,
        error: payload.isRequired ? validateRequired(payload.number) : '',
      },
    };

    next({
      ...action,
      payload: validatedPayload,
    });
    return;
  }

  next(action);
};

export const CheckboxChanged = (): Middleware => ({
  getState,
}: { getState: () => AppState }) => (next) => (action: SetCheckbox): void => {
  if (action.type === SET_CHECKBOX_WORK_WITH_US) {
    const {
      payload: {
        groupName,
        option,
        isChecked,
        validators,
      },
    } = action as SetCheckbox;

    let error = '';
    const { sdWorkWithUs } = getState();

    if (!sdWorkWithUs) {
      throw new Error('Failed loading module');
    }

    const existingBoxes = (
      sdWorkWithUs as {[key: string]: any} // eslint-disable-line
    )[groupName].value as {[key: string]: boolean};

    const updatedBoxes = {
      ...existingBoxes,
      [option]: isChecked,
    };

    validators.forEach((validate) => {
      const errorCode = validate(updatedBoxes);
      if (errorCode) {
        error = errorCode;
      }
    });

    const validatedPayload: SetCheckboxValidated['payload'] = {
      [groupName]: {
        value: updatedBoxes,
        error,
      },
    };

    next({
      ...action,
      payload: validatedPayload,
    });
    return;
  }

  next(action);
};

export const FormSubmitted = ({ api }: Services): Middleware => ({
  dispatch,
  getState,
}: Store) => (next) => async (action: SubmitForm): Promise<void> => {
  next(action);

  if (action.type === SUBMIT_FORM_SD_BUSINESS_WORK_WITH_US) {
    dispatch(setFormLoading(true));

    if (!api?.shipaDelivery?.CRM.createLead) {
      return;
    }
    const { sdWorkWithUs } = getState();

    if (!sdWorkWithUs) {
      throw new Error('Failed loading module');
    }

    const {
      firstName,
      lastName,
      companyName,
      phoneNumber,
      email,
      recaptcha,
      receiveEmails,
      industry,
      dailyVolume,
    } = sdWorkWithUs;

    let canSubmit = true;

    dispatch(setGeneralError(''));
    if (validateRequired(firstName.value)) {
      dispatch(setRequiredInput('firstName')(firstName.value));
      canSubmit = false;
    }
    if (validateRequired(lastName.value)) {
      dispatch(setRequiredInput('lastName')(lastName.value));
      canSubmit = false;
    }
    if (validateRequired(companyName.value)) {
      dispatch(setRequiredInput('companyName')(companyName.value));
      canSubmit = false;
    }
    if (validateRequired(phoneNumber.number)) {
      dispatch(setRequiredPhoneInput(
        phoneNumber.countryCode,
        phoneNumber.number,
      ));
      canSubmit = false;
    }
    if (validateEmail(email.value)) {
      dispatch(setRequiredEmail('email')(email.value));
      canSubmit = false;
    }
    if (validateRequired(industry.value)) {
      dispatch(setRequiredInput('industry')(industry.value));
      canSubmit = false;
    }
    if (validateRequired(dailyVolume.value)) {
      dispatch(setRequiredInput('dailyVolume')(dailyVolume.value));
      canSubmit = false;
    }
    if (validateRequired(recaptcha.value)) {
      dispatch(setRequiredInput('recaptcha')(recaptcha.value));
      canSubmit = false;
    }

    if (!canSubmit) {
      dispatch(setFormLoading(false));
      return;
    }

    try {
      await api.shipaDelivery.CRM.createLead({
        firstName: firstName.value,
        lastName: lastName.value,
        companyName: companyName.value,
        phoneNumber: {
          countryCode: phoneNumber.countryCode,
          number: phoneNumber.number,
        },
        email: email.value,
        receiveEmails: receiveEmails.value.agreed,
        industry: parseInt(industry.value, 10),
        dailyVolume: parseInt(dailyVolume.value, 10),
        marketingCampaign: 'Shipa.com-Business-Work-With-Us',
      });

      dispatch(setFormSuccess(true));
    } catch (error) {
      if (error instanceof AppError && error.code === 'validationError') {
        dispatch(setGeneralError(error.description));
      } else {
        dispatch(setGeneralError(
          error.description || error.message,
        ));
      }
      // eslint-disable-next-line no-console
      console.error(error.message);
    }

    dispatch(setFormLoading(false));
  }
};

export default [
  loadComponentSuccessMiddleware,
  InputChanged,
  PhoneInputChanged,
  FormSubmitted,
  CheckboxChanged,
];
